import NodeItem from 'o365.modules.CodeBuilder.NodeItem.ts'
import {TreeNode} from 'o365.modules.TreeObject.ts';
import {getComponentName} from 'o365.modules.CodeBuilder.constants.ts';


export default class CodeGenerator{

    static generateCode(pNode:TreeNode){
        let vCode = "";

        const vReturn =  this._getImports(pNode);

        vReturn.imports.sort().filter(function(item, pos, ary) {
            return !pos || item != ary[pos - 1];
        }).forEach(imp=>{
           // vCode+="\t";
            vCode+=`import ${getComponentName(imp)} from '${imp}'\r\n`;
        });
        vCode += "\r\n";


        vReturn.events.forEach(evt=>{
            //vCode+="\t";
            vCode+=`const ${evt.eventName} = (`;
                if(evt.eventParams){
                     vCode+=  evt.eventParams.map(p=>p.name).join(", ");
                }
                //gather params
            vCode+=`) =>{\r\n`;
          
            if(evt.eventParams){
                evt.eventParams.forEach(param =>{
                     vCode+="\t /**"
                    if(param.description) vCode+="\t * " +param.description + "\r\n" ;  
                    vCode+="\t * " +param.name+":"+param.type?.name + "\r\n" ;  
                    vCode+="\t **/" ;  
                })
                
            }
            vCode+=`\r\n}\r\n`;


        });
        console.log(vReturn);

        vCode += "\r\n";
      
        return vCode;
    }

    private static _getImports(pNode:TreeNode){
        let imports = [];
        let functions = [];
        let events = [];
        let refs = [];
        const vNode = pNode.item;

        if(vNode){
             if(!imports.find(x=>x==vNode.fileName)){
                imports.push(vNode.fileName)
           }
            vNode.props.props.filter(x=>x.isEvent && x.selectedValue).forEach(code=>{
                events.push(code);
            })

            vNode.props.props.filter(x=>x.name==="ref" && x.selectedValue).forEach(ref=>{
                refs.push(ref);
            })
        }

        pNode.children.forEach(child=>{
           
            const vReturn = this._getImports(child);
            events = events.concat(vReturn.events);
            imports = imports.concat(vReturn.imports);
            functions = functions.concat(vReturn.functions);
            refs = functions.concat(vReturn.refs);
        })

      

        return {
            imports:imports,
            functions:functions,
            events:events,
            refs:refs
        };
    }
}